import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as LogoText } from 'Assets/LogoAndTextWithAi.svg';
import Spinner from 'Components/Common/Spinner/Spinner';
import s from './AiTemplate.module.scss';
import { io, Socket } from 'socket.io-client';
import { PAGECRAFT_API_URL } from 'Constants';
import { rxBlocks, rxIsLoading } from 'rx/rxState';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrow-right.svg';
import { emulatePause, getUserId } from 'utils/api';
import { cloneDeep } from 'lodash';
import { createSocket } from 'utils/socket';
import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';

interface IQuestion {
  id: string;
  label: string;
}

interface IAnswer {
  id: string;
  text: string;
}

interface IParams {
  pageId: string;
}

interface IResult {
  id: string;
  type: string;
  text?: string;
  url?: string;
  urls?: string[];
}

interface ISectionSettings {
  aiField: string;
  content: string;
}

const AiPage = () => {
  const history = useHistory();
  const location = useLocation();

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [sectionSettings, setSectionSettings] = useState<ISectionSettings[][]>(
    []
  );
  const [userInput, setUserInput] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [loading, setLoading] = useState(false);
  const [aiResults, setAiResults] = useState<IResult[]>([]);
  const [socketResponse, setSocketResponse] = useState<any | null>(null);
  const [pageBlocks, setPageBlocks] = useState<any[]>([]);
  const [lastId, setLastId] = useState(0);
  const [aiName, setAiName] = useState('');
  const [percentDisplayed, setPercentDisplayed] = useState<number>(1);
  const [turnOnPercent, setTurnOnPecent] = useState<boolean>(false);

  const percent = useRef(0);
  const { pageId } = useParams<IParams>();
  const socket = useRef<null | Socket>(null);

  useEffect(() => {
    fetchAiPage(pageId);
    fetchPage(pageId);
  }, []);

  useEffect(() => {
    if (!socketResponse) {
      return;
    }
    if (socketResponse.progress) {
      percent.current = parseInt(socketResponse.progress);
    }
    if (socketResponse.error) {
      console.error(socketResponse.error);
      rxIsLoading.next(socketResponse.error);
    }
    if (socketResponse.results) {
      console.log('socketResponse.results:', socketResponse.results);
      const resultsFromAnswers: IResult[] = answers.map((answer) => ({
        id: answer.id,
        type: 'text',
        text: answer.text,
      }));

      const allResults = [...resultsFromAnswers, ...socketResponse.results];
      setAiResults([...resultsFromAnswers, ...socketResponse.results]);
      const newSections = fillSectionData(pageBlocks, allResults);

      // const suggestions: any = {};

      // socketResponse.results.map((result: IResult) => {
      //   if (result.type === 'text') {
      //     suggestions[result.id] = result.text
      //   }
      // });

      pushDataToEditor({
        lastId: lastId,
        blocks: newSections,
        // aiSuggestion: suggestions
      });
      rxIsLoading.next('');
    }
    return () => {
      socket.current?.off('connect', () => {});
    };
  }, [socketResponse]);

  useEffect(() => {
    if (aiResults.length) {
      percent.current = 0;
      setLoading(false);
    }
  }, [aiResults]);
  console.log(percent.current, 'percentDisplayed1');
  console.log(percentDisplayed, 'percentDisplayed2');

  useEffect(() => {
    if (percent.current === 0) {
      setPercentDisplayed(0);
      return;
    }

    let intervalTime = 1000;

    if (percent.current > percentDisplayed) {
      intervalTime = 300;
    }

    if (turnOnPercent) {
      const interval = setInterval(() => {
        setPercentDisplayed((prev) => {
          if (prev < percent.current) {
            return Math.min(prev + 1, percent.current, 99);
          }
          return Math.min(prev + 1, 99);
        });
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [percent.current, percentDisplayed, turnOnPercent]);

  const getQuestion = () => {
    const question = questions[questionIndex];
    return question;
  };

  const fetchAiPage = async (pageId: string) => {
    setLoading(true);
    const aiPage = await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_PAGE,
      values: {
        pageId,
      },
    });
    console.log('aiPage.questions', aiPage);
    setQuestions(aiPage.questions);
    setLoading(false);
    setAiName(aiPage.name);
  };

  const setBlockIds = (blocks: any[]) => {
    let currentId = 0;
    const newBlocks = blocks.map((currentBlock) => {
      const { block, lastId } = setIdsInBlock(currentBlock, currentId);
      currentId = lastId;
      return block;
    });
    return { lastId: currentId, blocks: newBlocks };
  };

  const setIdsInBlock = (currentBlock: any, commonLastId: number) => {
    commonLastId++;
    const updatedBlock = cloneDeep(currentBlock);
    updatedBlock.id = commonLastId;
    if (updatedBlock.children?.length) {
      updatedBlock.children = updatedBlock.children.map((child: any) => {
        const { block, lastId } = setIdsInBlock(child, commonLastId);
        commonLastId = lastId;
        return block;
      });
    }
    return { block: updatedBlock, lastId: commonLastId };
  };

  const fetchPage = async (pageId: string) => {
    const page = await fetch(`${PAGECRAFT_API_URL}/pages/load/${pageId}`).then(
      (res) => res.json()
    );
    setPageBlocks(page.pages.optin.blocks);
    setLastId(page.pages.optin.lastId);
  };

  const submitQuestion = () => {
    if (!userInput.length) {
      return;
    }

    const newAnswers = [...answers];
    newAnswers.push({
      id: getQuestion().id,
      text: userInput,
    });
    setAnswers(newAnswers);

    setUserInput('');

    if (questionIndex < questions.length - 1) {
      const nextIndex = questionIndex + 1;
      setQuestionIndex(nextIndex);
    } else {
      startGeneratePrompts(newAnswers);
    }
  };

  const submitQuestionGenerateButton = () => {
    setTurnOnPecent(true);
    submitQuestion();
  };

  const startGeneratePrompts = async (answers: IAnswer[]) => {
    setLoading(true);

    const payload: any = {
      pageId,
      questions: answers,
    };

    const userId = getUserId();

    if (userId) {
      payload.userId = userId;
    }

    socket.current?.off('connect', () => {});
    socket.current = createSocket();
    socket.current.on('connect', () => {
      if (!socket.current) {
        return;
      }
      connectSocket(socket.current, payload);
    });
    percent.current = 1;
    await emulatePause(1000);
  };

  const connectSocket = (socket: Socket, payload: any) => {
    socket.emit('generate-ai-page', payload);
    socket.on('ai-page-generated', (data: any) => {
      setSocketResponse(data);
    });
  };

  const fillSectionData = (sectionsData: any[], results: IResult[]): any[] => {
    let newSectionsData: any[] = [];
    sectionsData.forEach((data, sectionIndex) => {
      const newData = setAiFieldsInBlocks(data, results);
      newSectionsData.push(newData);
    });
    return newSectionsData;
  };

  const fillItem = (block: any, results: IResult[]): any => {
    const newBlock = cloneDeep(block);
    if (!newBlock.aiField) {
      return newBlock;
    }

    for (const result of results) {
      if (result.type === 'text') {
        if (!newBlock.text?.value?.blocks?.length) {
          console.error('INCORRECT SECTION DATA', newBlock.text);
        } else if (result.id === newBlock.aiField) {
          const textBlock = newBlock.text.value.blocks[0];
          newBlock.text.value.blocks[0] = {
            ...newBlock.text.value.blocks[0],
            inlineStyleRanges: textBlock.inlineStyleRanges
              .filter((style: any) => style.offset == 0)
              .map((style: any) => ({
                ...style,
                length: result.text!.length,
              })),
            text: result.text,
          };
        }
      } else if (result.type === 'image' && result.id === newBlock.aiField) {
        if (result.urls) {
          if (newBlock.type === 'Section') {
            newBlock.image = result.urls[0];
          } else {
            newBlock.imageUrl = result.urls[0]; //TODO: replace 'imageUrl' attribute with 'image' so it will be consistent with Section block.
          }
        }
      }
    }

    return newBlock;
  };

  const setAiFieldsInBlocks = (data: any, results: IResult[]) => {
    let newData = cloneDeep(data);
    if (newData.aiField) {
      newData = fillItem(data, results);
    }
    if (newData.blocks?.length) {
      newData.blocks = newData.blocks.map((block: any) => {
        if (block.aiField) {
          block = fillItem(block, results);
        }
        return block;
      });
    }
    if (newData.children?.length) {
      newData.children = newData.children.map((child: any) => {
        return setAiFieldsInBlocks(child, results);
      });
    }
    return newData;
  };

  const pushDataToEditor = async (newpage: any) => {
    delete newpage.aiSuggestion;
    rxBlocks.next(newpage);
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('projectId');

    const userId = getUserId();

    const res = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PAGE_MUTATION,
      values: {
        name: aiName,
        data: JSON.stringify(newpage),
        funnelId: projectId,
      },
      headerType: 'USER-AUTH',
    });

    const pageId = res._id;

    history.push(`/edit/edit/${userId}/${pageId}/optin`);
  };

  return (
    <div className={s.mainContent}>
      <div className={s.logo}>
        <LogoText />
      </div>
      {loading && percentDisplayed < 109 && (
        <div className={s.loadingBlock}>
          <LoadingSpinner />
          {percent.current > 0 && (
            <div className={s.progressBarBlock}>
              <div className={s.loadingBar}>
                <div>
                  <div className={s.progressBar}>
                    <div
                      className={s.progressPercent}
                      style={{ width: `${percentDisplayed}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={s.percent}>{percentDisplayed} %</div>
            </div>
          )}
        </div>
      )}
      {!loading && questions.length > 0 && (
        <section>
          <div className={s.inputBlock}>
            <label>{getQuestion().label}</label>
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
          </div>
          <div className={s.buttonBlock}>
            {questionIndex < questions.length - 1 ? (
              <button onClick={() => submitQuestion()}>
                Next
                <ArrowRight className={s.icon} />
              </button>
            ) : (
              <button
                onClick={() => submitQuestionGenerateButton()}
                className={s.generate}
              >
                Generate
              </button>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default AiPage;
