import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IBookChapter, IBookData, BookStateType } from 'Components/books/types';
import s from './BookGenerator.module.scss';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { Socket } from 'socket.io-client';
import BookEditorView from 'Components/books/BookEditorView';
import { AIBookGenerator } from 'Components/books/bookGeneration';
import {
  rxBlocks,
  rxBookDataForSave,
  rxBookNeedSave,
  rxInsertSection,
  rxReplaceSection,
  rxRemoveSection,
  rxDuplicateSection,
  rxBlockIndexForBook,
  rxSections,
  rxSelectBookImg,
  rxReorderSection,
  rxCurrentUser,
} from 'rx/rxState';
import clsx from 'clsx';
import { useObservable } from 'utils/UseObservable';
import SaveSpinner from 'Components/Common/SaveSpinner/SaveSpinner';
import BookInfoBlockEditor, {
  GenerationError,
} from 'Components/books/BookInfoBlock/BookInfoBlockEditor';
import { getBySocket, createSocket } from 'utils/socket';
import { cloneDeep } from 'lodash';
import { getToken } from 'utils/api';
import { setIdsOrder } from 'utils/blocks';
import { IUserDetails } from 'types/user';
import InappropriateAIContentPopup from 'Components/Popups/InappropriateAIContentPopup';
import BookDetails from 'Components/Popups/BookDetails/BookDetails';
import { ReactComponent as MenuIcon } from 'Assets/burger.svg';

interface IChaptersGenerationResponse {
  title: string;
  text: string;
}

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const IMAGE_SECTION_TITLE = 'Image page';
export const SIDE_MENU_ID = 'book-editor-side-menu';

const BookEditor = () => {
  const { bookId } = useParams<any>();
  /**
   * State
   */
  const [globalStatus, setGlobalStatus] = useState<BookStateType>('INITIATING');
  const [bookData, setBookData] = useState<IBookData | null>(null);
  const [bookSaveLoading, setBookSaveLoading] = useState<boolean>(false);
  const [chapterInProgress, setChapterInProgress] = useState<null | number>(
    null
  );
  const [allChaptersGenerationResponse, setAllChaptersGenerationResponse] =
    useState<IChaptersGenerationResponse | null>(null);
  const [bookCoverFromSocket, setBookCoverFromSocket] = useState<null | string>(
    null
  );
  const [chapterRegenerateTriggeredIndex, setChapterRegenerateTriggeredIndex] =
    useState<number>();
  const [generationError, setGenerationError] = useState<GenerationError>();

  /**
   * Observable RX
   */
  const bookDataForSave: any = useObservable(rxBookDataForSave);
  const sectionToRemove: string | undefined = useObservable(rxRemoveSection);
  const sectionToReOrder: any = useObservable( rxReorderSection );
  const sectionToDuplicate: string | undefined =
    useObservable(rxDuplicateSection);
  const blocks: any = useObservable(rxBlocks);
  const addBlankChapterIndex = useObservable(rxBlockIndexForBook);
  const selectBookImg: any = useObservable(rxSelectBookImg);
  const currentUser = useObservable<IUserDetails>(rxCurrentUser);

  const socket = useRef<Socket | null>(null);
  const [view, setView] = useState<any>();

  const [chapterMenuOpen, setChpaterMenuOpen] = useState(false);

  let connectionAmount = 0;

  useEffect(() => {
    // fetchInitialData();

    socket.current = createSocket();

    socket.current.on('reconnect', () => {
      console.log('reconnect');
    });
    socket.current.on('reconnecting', () => {
      console.log('reconnecting');
    });
    socket.current.on('connect_error', (err) => {
      console.log('connection error:', err);
    });
  }, []);

  useEffect(() => {
    const connect = () => {
      console.log(
        'connection with server established: ',
        globalStatus,
        connectionAmount
      );
      getChaptersBySocket();
      if (socket.current) fetchInitialData();
    };
    socket.current!.on('connect', connect);
    return () => {
      socket.current!.off('connect', connect);
    };
  }, [globalStatus]);

  useEffect(() => {
    if (chapterInProgress) {
      return;
    }
    var data = addBlankChapterIndex as any;
    const index = data?.index;
    if (index !== undefined) {
      if (index === 0) {
        recoverBookCover();
      }
      if (!index) {
        return;
      }
      addImageChapter(index);
    }
  }, [addBlankChapterIndex]);

  useEffect(() => {
    if (
      sectionToRemove === undefined ||
      sectionToRemove === null ||
      !blocks ||
      !bookData
    ) {
      return;
    }

    const index = (sectionToRemove as any)?.index;
    if (index) {
      handleDeleteChapterFromEditor(index);
    }
  }, [sectionToRemove]);

  useEffect(() => {
    if (
      sectionToReOrder === undefined ||
      sectionToReOrder === null ||
      !blocks ||
      !bookData
    ) {
      return;
    }
    const fromIndex = sectionToReOrder.fromIndex-1;
    const toIndex = sectionToReOrder.toIndex-1;
    let newChapters: IBookChapter[] = [...bookData.chapters];
    const tempChapter = newChapters.splice(fromIndex, 1)[0];
    newChapters.splice(toIndex, 0, tempChapter);
    const newBookData = { ...bookData };
    newBookData.chapters = newChapters;
    setBookData(newBookData);

  }, [sectionToReOrder])

  useEffect(() => {
    if (
      sectionToDuplicate === undefined ||
      sectionToDuplicate === null ||
      !blocks ||
      !bookData
    ) {
      return;
    }

    const index = (sectionToDuplicate as any)?.index;
    if (index) {
      handleDuplicateChapterFronEditor(index);
    }
  }, [sectionToDuplicate]);

  useEffect(() => {
    if (chapterRegenerateTriggeredIndex !== undefined) {
      onChapterRegenerate(chapterRegenerateTriggeredIndex);
      setChapterRegenerateTriggeredIndex(undefined);
    }
  }, [chapterRegenerateTriggeredIndex]);

  const checkUserAccess = async () => {
    const access = await graphQlCall({
      queryTemplateObject: QUERIES.CHECK_SUBSCRIPTION_ACCESS,
      headerType: 'USER-AUTH',
    });
    if (currentUser?.scopes?.includes('BOOKLE_GENERAL_ACCESS')) {
      access.bookle = true;
    }
    if (!access.bookle) {
      console.error('no bookle access');
      // window.open('/console/login', '_self');
    }
  };

  const recoverBookCover = () => {
    setChapterInProgress(-1);
    if (!bookData) {
      setChapterInProgress(null);
      return;
    }
    const firstBlock = blocks.blocks[0];
    if (firstBlock.image && firstBlock.children?.length) {
      rxRemoveSection.next({ index: 0 });
    }
    let section: any;
    if (
      bookData.data?.blocks?.length &&
      bookData.data.blocks[0].image &&
      bookData.data.blocks[0].children.length
    ) {
      section = bookData.data.blocks[0];
    } else {
      const generator = new AIBookGenerator();
      const generatedBlocks = generator.createInitialData(
        bookData.title,
        selectBookImg || bookData.coverImageUrl
      );
      section = generatedBlocks.blocks[0];
    }

    const optimizedData = setIdsOrder([section]);
    rxInsertSection.next({
      index: 0,
      section: optimizedData.blocks[0],
    });
    setChapterInProgress(null);
  };

  const handleDuplicateChapterFronEditor = (index: number) => {
    if (!bookData) {
      return;
    }
    const newChapters = [...bookData.chapters];
    const chapter = newChapters[index - 1];
    newChapters.splice(index - 1, 0, chapter);
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  };

  const handleDeleteChapterFromEditor = (index: number) => {
    if (!bookData) {
      return;
    }
    const newChapters = [...bookData.chapters];
    newChapters.splice(index - 1, 1);
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  };

  const fetchInitialData = async () => {
    setChapterInProgress(null);
    await checkUserAccess();
    await getTemplateSections();
    await fetchBookData(bookId);
  };

  const fetchBookData = async (bookId: string) => {
    try {
      const newBookData: IBookData = await graphQlCall({
        queryTemplateObject: QUERIES.GET_BOOK_FOR_EDIT_BY_ID,
        headerType: 'USER-AUTH',
        values: {
          id: bookId,
        },
      });

      if (!newBookData) {
        throw new Error(`fetching book error`);
      }
      setBookData(newBookData);
      setGlobalStatus('INITIATING');
      const initialGlobalStatus = calculateInitialState(newBookData);
      setGlobalStatus(initialGlobalStatus);
    } catch (error) {
      console.error('book loading error:', error);
      // const url = `/console/login`;
      // window.open(url, '_self');
    }
  };

  const calculateInitialState = (bookData: IBookData): BookStateType => {
    const sectionsCount = bookData.data?.blocks?.length || 0;
    const chaptersCount = bookData.chapters.length;
    let chaptersGeneratedCount = 0;
    bookData.chapters.forEach((chapter) => {
      if (chapter.text?.length || chapter.title === IMAGE_SECTION_TITLE) {
        chaptersGeneratedCount++;
      }
    });
    if (bookData.generating || chaptersGeneratedCount < chaptersCount) {
      return 'CONTINUE_GENERATION';
    }
    if (chaptersGeneratedCount === chaptersCount && sectionsCount === 0) {
      return 'INITIAL_RENDERING';
    }
    if (
      chaptersGeneratedCount === chaptersCount &&
      sectionsCount - 1 < chaptersCount
    ) {
      return 'CONTINUE_RENDERING';
    }
    return 'ALL_COMPLETED';
  };

  useEffect(() => {
    console.log('globalStatus updated:', globalStatus);
    switch (globalStatus) {
      case 'INITIAL_RENDERING':
        initialRendering();
        break;
      case 'ALL_COMPLETED':
        renderCompletedBook();
        break;
      case 'CONTINUE_GENERATION':
        continueChaptersGeneration();
        break;
      case 'CONTINUE_RENDERING':
        continueChaptersRender();
        break;
      default:
        break;
    }
  }, [globalStatus]);

  const initialRendering = () => {
    if (!bookData) {
      throw new Error('NO BOOK DATA');
    }

    const valuedChapters = bookData.chapters.filter((chapter) => chapter.text);

    const blocksData: any = AIBookGenerator.build({
      chapters: valuedChapters.map((chapter) => ({
        chapter: chapter.text || '',
        title: chapter.title,
        bookName: bookData.title,
      })),
      coverUrl: bookData.coverImageUrl,
      bookTitle: bookData.title,
    });

    rxBlocks.next(blocksData);
  };

  const getChaptersBySocket = () => {
    if (!socket.current) {
      return;
    }

    const eventName = 'response-book-all-chapters';
    if (socket.current.listeners(eventName).length === 0) {
      console.log('enabling server listener for chapters');
      socket.current.on(eventName, (data) => {
        if (data.error) {
          console.error('book generating error', data.error);
          setGenerationError({
            error: data.error,
            initialGeneration: true,
          });
          socket.current?.off(eventName);
          return;
        }
        if (!data.chapterText || !data.chapterTitle) {
          return;
        }
        console.log('Got response from socket: ', data);
        setAllChaptersGenerationResponse({
          text: data.chapterText as string,
          title: data.chapterTitle as string,
        });
      });
    }
  };

  useEffect(() => {
    if (allChaptersGenerationResponse) {
      handleAllChapterGenerationResponse(allChaptersGenerationResponse);
    }
  }, [allChaptersGenerationResponse]);

  const handleAllChapterGenerationResponse = (
    response: IChaptersGenerationResponse
  ) => {
    if (!bookData) {
      throw new Error('NO BOOK DATA');
    }
    const { text, title } = response;

    //generator
    const generator = new AIBookGenerator();
    const blockData = generator.createTextBlock(
      extractChapterTitle(title),
      text,
      bookData.title
    );
    rxInsertSection.next({ section: blockData });

    let nextChapterIndex: number | undefined;
    bookData.chapters.forEach((currentChapter, index) => {
      if (currentChapter.title === title) {
        currentChapter.text = text;
        nextChapterIndex = index + 1;
      }
    });

    if (nextChapterIndex === undefined) {
      throw new Error(`Chapter with title ${title} not found`);
    }

    if (nextChapterIndex < bookData.chapters.length) {
      setChapterInProgress(nextChapterIndex);
    } else {
      setChapterInProgress(null);
    }
  };

  const getTemplateSections = async () => {
    const sections = await graphQlCall({
      queryTemplateObject: QUERIES.GET_SECTIONS_BY_NAMES,
      values: {
        names: ['AI-BookChapter-1', 'AI-BookCover-1'],
      },
    });
    rxSections.next(sections);
  };

  const saveBook = async (dataForSave: any, chaptersForSave: any = null) => {
    if (bookData?._id) {
      setBookSaveLoading(true);
      generateBookCover();

      const chapters = chaptersForSave ? chaptersForSave : bookData.chapters;
      const values: any = {
        id: bookData?._id,
        data: JSON.stringify(dataForSave),
        title: bookData.title,
        chapters: JSON.stringify(chapters),
      };
      // console.log('[SAVE] bookData.chapters', bookData.chapters)

      await graphQlCall({
        queryTemplateObject: QUERIES.UPDATE_BOOK_MUTATION,
        headerType: 'USER-AUTH',
        values,
      });

      setBookSaveLoading(false);
    }
    rxBookNeedSave.next(false);
  };

  const saveChapter = async (
    index: number,
    chapterData: Partial<IBookChapter>
  ) => {
    if (bookData?._id) {
      setBookSaveLoading(true);

      const values: any = {
        bookId: bookData._id,
        index,
        ...chapterData,
      };

      await graphQlCall({
        queryTemplateObject: QUERIES.UPDATE_CHAPTER_MUTATION,
        headerType: 'USER-AUTH',
        values,
      });

      setBookSaveLoading(false);
    }
    rxBookNeedSave.next(false);
  };

  const handleChapterNotesRegenerate = async (
    index: number,
    chapterNotes: string[]
  ) => {
    updateChapterData(index, { comments: chapterNotes });
    await saveChapter(index, { comments: chapterNotes });
    setChapterRegenerateTriggeredIndex(index);
  };

  const generateBookCover = async () => {
    if (!socket.current) {
      console.error('no socket');
      return;
    }
    const image = getCoverImage();
    const coverElement = document.getElementById('1');
    if (!coverElement) {
      console.error('no cover element');
      return;
    }
    coverElement.style.backgroundImage = `url("${image}")`;
    coverElement.style.backgroundSize = 'cover';

    const element = document.getElementById('1');
    const html = element?.outerHTML;
    if (!html) {
      console.error('could not get book cover html');
      return;
    }
    const payload = {
      html,
      width: 800,
      height: 1100,
      bookId,
    };
    const res: any = await getBySocket({
      payload,
      emitEventName: 'create-book-cover',
      resultEventName: 'screenshot-created',
      socket: socket.current,
    });

    return res;
  };

  const getCoverImage = () => {
    const allBlocks: any = { ...(blocks as any) };
    if (!allBlocks?.blocks?.length) {
      return null;
    }
    const block: any = allBlocks.blocks[0];
    return block.image;
  };

  useEffect(() => {
    if (bookDataForSave && chapterInProgress === null) {
      saveBook(bookDataForSave);
    }
  }, [bookDataForSave]);

  const renderCompletedBook = () => {
    if (!bookData?.data) {
      throw new Error('NO BOOK DATA');
    }
    rxBlocks.next(bookData.data);
  };

  const continueChaptersRender = () => {
    if (!bookData?.data || !bookData.chapters) {
      throw new Error('NO BOOK DATA/CHAPTERS');
    }
    if (!socket.current) {
      throw new Error('NO SOCKET');
    }
    const sectionsCount = bookData.data?.blocks?.length || 0;

    if (sectionsCount > 1) {
      rxBlocks.next(bookData.data);
    } else {
      initialRendering();
    }

    let index = 0;
    if (bookData?.chapters) {
      for (const chapter of bookData?.chapters) {
        if (isSectionWithTitleExist(chapter.title) === false) {
          console.log('adding chapter with index: ', index, chapter.title);
          const generator = new AIBookGenerator();
          const blockData = generator.createTextBlock(
            chapter.title,
            chapter.text!,
            bookData.title
          );
          rxInsertSection.next({ section: blockData, index: index + 1 });
        }
        index += 1;
      }
    }
  };

  const isSectionWithTitleExist = (title: string) => {
    const blocks = view.getBlocksByAiField('paragraph');
    for (const block of blocks) {
      if (block.getText() === title) {
        return true;
      }
    }
    return false;
  };

  const continueChaptersGeneration = async () => {
    if (!bookData?.data || !bookData.chapters) {
      throw new Error('NO BOOK DATA/CHAPTERS');
    }
    if (!socket.current) {
      throw new Error('NO SOCKET');
    }
    getBookCoverFromSocket();
    const sectionsCount = bookData.data?.blocks?.length || 0;

    if (sectionsCount > 1) {
      rxBlocks.next(bookData.data);
    } else {
      initialRendering();
    }

    let chaptersWithText = 0;
    bookData.chapters.forEach((chapter) => {
      if (chapter.text?.length) {
        chaptersWithText++;
      }
    });

    if (chaptersWithText < bookData.chapters.length) {
      socket.current.emit('continue-book-generation', { bookId });
      setChapterInProgress(chaptersWithText);
    } else {
      setChapterInProgress(null);
    }
  };

  useEffect(() => {
    if (bookCoverFromSocket && !view?.blocks[0]?.image?.value) {
      insertCoverToSection(bookCoverFromSocket);
    }
  }, [bookCoverFromSocket]);

  const insertCoverToSection = (coverImageUrl: string) => {
    if (!view?.blocks[0]) {
      console.error('no blocks in view');
    }
    view.blocks[0].image.value = coverImageUrl;
    view.update();
  };

  const getBookCoverFromSocket = () => {
    if (!bookData?.coverImageUrl && socket.current && bookData) {
      socket.current.emit('generate-openai-image', {
        text: bookData.title,
        ratio: '6:4',
        imageCount: 1,
        bookId: bookData._id,
      });

      const eventName = 'open-ai-image-generated';
      if (socket.current.listeners(eventName).length === 0) {
        socket.current.on(eventName, (data) => {
          if (data.imageUrl) {
            setBookCoverFromSocket(data.imageUrl);
          }
        });
      }
    }
  };

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (!bookData) {
      return;
    }

    rxReorderSection.next({
      fromIndex: dragIndex + 1,
      toIndex: dropIndex + 1,
    } as any);
  };

  const deleteChapter = async (index: number) => {
    if (!bookData) {
      return;
    }

    rxRemoveSection.next({ index: index + 1 });
  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0 || !bookData) {
      return;
    }
    updateChapterData(index, {
      title: value,
    });
  };

  const addChapter = async (index: number, action: 'add' | 'insert') => {
    if (!socket.current || !bookData) {
      return;
    }
    // await saveBook(blocks);
    const tempChapter: IBookChapter = {
      comments: [],
      title: 'new chapter',
      text: '',
    };
    let newChapters: IBookChapter[] = [];
    if (action === 'insert') {
      bookData.chapters.forEach((chapter, chapterIndex) => {
        if (chapterIndex === index) {
          newChapters.push(tempChapter);
        }
        newChapters.push(chapter);
      });
    } else {
      newChapters = [...bookData.chapters];
      newChapters.push(tempChapter);
    }
    setChapterInProgress(index);

    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);

    const payloadAddChapter = {
      bookId,
      token: getToken(),
      positionBefore: index,
      action: action,
    };

    //getting new chapter
    const dataAddChapter: any = await getBySocket({
      emitEventName: 'ai-book-add-chapter',
      resultEventName: 'response-book-add-chapter',
      payload: payloadAddChapter,
      socket: socket.current,
    });

    //setting title
    if (!newChapters[index]) {
      newChapters.push({
        title: dataAddChapter.chapterTitle,
        text: '',
        comments: [],
      });
    } else {
      newChapters[index].title = dataAddChapter.chapterTitle;
    }

    setNewChaptersInBook(newChapters);

    //adding placeholder for new chapter
    const generator = new AIBookGenerator();
    const blockDataPlaceholder = generator.createTextBlock(
      extractChapterTitle(dataAddChapter.chapterTitle as string),
      'text generation in progress...',
      bookData.title
    );

    rxInsertSection.next({
      index: index + 1,
      section: blockDataPlaceholder,
    });

    //saving for chapters
    await saveBook(blocks, newChapters);

    //generating text for chapter
    const payloadChapterGenerateText = {
      bookId,
      token: getToken(),
      chapterIndex: index,
      chapterTitle: dataAddChapter.chapterTitle,
      requireContent: 'text',
    };

    const resChapterGenerateTex: any = await getBySocket({
      payload: payloadChapterGenerateText,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });

    if (resChapterGenerateTex.error) {
      console.error('add chapter error', resChapterGenerateTex.error);
      setGenerationError({
        error: resChapterGenerateTex.error,
        initialGeneration: false,
      });
      return;
    }

    const blockData = generator.createTextBlock(
      extractChapterTitle(dataAddChapter.chapterTitle as string),
      resChapterGenerateTex.text,
      bookData.title
    );

    rxReplaceSection.next({
      index: index + 1,
      section: blockData,
    });

    newChapters[index].text = resChapterGenerateTex.text;
    setNewChaptersInBook(newChapters);
    setChapterInProgress(null);
  };

  const onChapterRegenerate = async (
    index: number,
    continueGeneration?: boolean
  ) => {
    if (!socket.current || !bookData) {
      return;
    }
    setChapterInProgress(index);
    await saveBook(blocks);

    const eventName = continueGeneration
      ? 'continue-regenerate-chapter'
      : 'ai-book-re-chapter';
    const regenerateTitlePayload = {
      bookId,
      token: getToken(),
      requireContent: 'title',
      chapterIndex: index,
    };
    const regenerateTitleRes: any = await getBySocket({
      payload: regenerateTitlePayload,
      emitEventName: eventName,
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    if (regenerateTitleRes.error) {
      console.error('regenerate chapter title error', regenerateTitleRes.error);
      setGenerationError({
        error: regenerateTitleRes.error,
        initialGeneration: false,
      });
      return;
    }
    const regenerateChapterTextPayload = {
      bookId,
      token: getToken(),
      requireContent: 'text',
      chapterIndex: index,
    };
    const regenerateChapterTextRes: any = await getBySocket({
      payload: regenerateChapterTextPayload,
      emitEventName: eventName,
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    if (regenerateChapterTextRes.error) {
      console.error(
        'regenerate chapter text error',
        regenerateChapterTextRes.error
      );
      setGenerationError({
        error: regenerateChapterTextRes.error,
        initialGeneration: false,
      });
      return;
    }
    const generator = new AIBookGenerator();
    const blockData = generator.createTextBlock(
      extractChapterTitle(regenerateTitleRes.text as string),
      regenerateChapterTextRes.text,
      bookData.title
    );
    rxRemoveSection.next({ index: index + 1 });
    rxInsertSection.next({
      index: index + 1,
      section: blockData,
    });

    updateChapterData(index, {
      text: regenerateChapterTextRes.text,
      title: regenerateTitleRes.text,
    });
    setChapterInProgress(null);
  };

  const onBookTitleEdited = async (value: string) => {
    if (value.trim().length === 0 || !bookData) {
      return;
    }
    const newBookData = cloneDeep(bookData);
    newBookData.title = value;
    setBookData(newBookData);
  };

  const addImageChapter = async (indexSection: number) => {
    if (!bookData) {
      return;
    }
    setChapterInProgress(-1);

    const newChapters: IBookChapter[] = [];
    bookData.chapters.forEach((chapter, chapterIndex) => {
      if (chapterIndex === indexSection - 1) {
        newChapters.push({
          title: IMAGE_SECTION_TITLE,
          comments: [],
          text: 'image',
        });
      }
      newChapters.push(chapter);
    });
    setNewChaptersInBook(newChapters);

    const generator = new AIBookGenerator();
    const blockData = generator.createBlankPage();

    rxInsertSection.next({
      index: indexSection,
      section: blockData,
    });
    setChapterInProgress(null);
  };

  const handleManualRegenerationTrigger = () => {
    if (generationError?.initialGeneration) {
      socket.current?.emit('ai-book-all-chapters', { bookId });
      getChaptersBySocket();
    } else {
      if (chapterInProgress) onChapterRegenerate(chapterInProgress, true);
    }
    setGenerationError(undefined);
  };

  const updateChapterData = (chapterIndex: number, data: any) => {
    if (!bookData?.chapters || chapterIndex >= bookData?.chapters.length) {
      return;
    }
    const newChapters = [...bookData.chapters];
    newChapters[chapterIndex] = {
      ...newChapters[chapterIndex],
      ...data,
    };
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);

    if(data.title){
      //Due to Book Cover section we have to add +1 to index
      const chapterSection = view.blocks[ chapterIndex+1 ];
      
      //default structure of chapter serctions is: BlockSection->BlockRow->[BlockText, BlockImage, BlockText, BlockText]
      const blockRow = chapterSection.children[0];
      const blockChapterTitle = blockRow.children[2];
      blockChapterTitle.setText(data.title);
    }
  };

  const setNewChaptersInBook = (newChapters: IBookChapter[]) => {
    if (!bookData) {
      return;
    }
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  };
  const viewReferenceCallback = (v: any) => {
    setView(v);
  };

  const handleChpaterMenuOpen = () => {
    setChpaterMenuOpen(!chapterMenuOpen);
  };

  return (
    <div className={s.bookEditorWrapper}>
      {/* <div className={clsx(s.bookInfoAndEditorContainer, {[s.moveRight]:!chapterMenuOpen})}> */}
      <div
        className={clsx(s.showMenuButton, { [s.moveRight]: !chapterMenuOpen })}
        onClick={handleChpaterMenuOpen}
      >
        <MenuIcon />
      </div>
      <div
        className={clsx(s.bookEditorSideMenu, {
          [s.moveRight]: !chapterMenuOpen,
        })}
        id={SIDE_MENU_ID}
      >
        <BookInfoBlockEditor
          bookData={bookData}
          generationError={generationError}
          onManualRegenerationTrigger={handleManualRegenerationTrigger}
          onBookTitleEdited={onBookTitleEdited}
          onChapterTitleEdited={onChapterTitleEdited}
          deleteChapter={deleteChapter}
          onChapterAdd={addChapter}
          reorderChapter={reorderChapter}
          endReorderChapter={() => {}}
          onChapterRegenerate={onChapterRegenerate}
          onChapterNotesRegenerate={handleChapterNotesRegenerate}
          addingChapterIndex={chapterInProgress}
          onRestoreCover={recoverBookCover}
          view={view}
        />
      </div>
      <div className={s.bookEditorView}>
        <BookEditorView
          ref={view}
          inProgress={chapterInProgress !== null}
          referenceCallback={viewReferenceCallback}
        />
      </div>
      {/* </div>  */}
      {bookSaveLoading && <SaveSpinner />}
      <InappropriateAIContentPopup />
      <BookDetails />
    </div>
  );
};

export default BookEditor;
