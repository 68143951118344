import { EventHandler } from 'react';
import { TOOLBAR_ID } from '../Components/Toolbar/ToolbarPanel';

export const isToolbarAction: EventHandler<any> = (e) => {
  const toolbar = document.getElementById(TOOLBAR_ID);
  return toolbar && toolbar.contains(e.target);
};

export const kebabize = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

export const classnames = (...args: Array<string | void>): string =>
  args.filter(Boolean).join(' ');

export function randomElement<T = any>(array: T[]): T {
  return array[Math.floor(array.length * Math.random())];
}
