import React, { Component } from 'react';
import styled from 'styled-components';

const DigitStyle = styled.div.attrs((props) => ({
  style: {
    marginTop: props.number * (props.size == 'small' ? -1.375 : -1.4) + 'em',
    transition: props.transition ? 'margin-top 0.4s ease' : 'none',
  },
}))`
  width: 40%;
`;

const ContainerStyle = styled.div`
  overflow: hidden;
  height: ${(props) => props.height}px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
`;

export default class ShiftClock extends Component {
  constructor(props) {
    super(props);

    this.offset = [];
    this.state = {
      shift: false,
    };
    this.transition = false;
    this.onTransitionComplete = this.onTransitionComplete.bind(this);
  }
  onTransitionComplete() {
    if (this.props.value[1] === '0') {
      this.setState({ shift: true });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value) {
      setTimeout(this.onTransitionComplete, 500);
      if (this.state.shift) {
        this.setState({ shift: false });
        return false;
      }
    }
    return true;
  }

  render() {
    let transition = true;
    let value = [];
    value[0] = this.props.value[0];
    value[1] = this.props.value[1];
    if (this.state.shift) {
      value[1] = 10;
      transition = false;
    }

    return (
      <ContainerStyle height={this.props.height}>
        <DigitStyle number={value[0]} size={this.props.size} transition={true}>
          0 1 2 3 4 5 6 7 8 9 0
        </DigitStyle>
        <DigitStyle
          number={value[1]}
          size={this.props.size}
          transition={transition}
        >
          0 1 2 3 4 5 6 7 8 9 0
        </DigitStyle>
        {/* {this.props.value} */}
      </ContainerStyle>
    );
  }
}
