// import QUERIES from 'graphql/queries';
import getBrowserFingerprint from 'get-browser-fingerprint';
// import { graphQlCall } from 'graphql/utils';
import { ANALYTIC_API } from 'Constants';

export const sendUserEvent = async ({ type, value, pageId }) => {
  const domFunnel = document.getElementById('meta-funnel-id');
  let funnelId = pageId;
  if (!pageId && domFunnel) {
    funnelId = domFunnel.getAttribute('data');
  }
  if (!funnelId || funnelId === 'new') {
    console.error('FunnelId not found');
    return;
  }
  const fingerprint = `${getBrowserFingerprint()}`;
  // graphQlCall({
  //   queryTemplateObject: QUERIES.ADD_STATISTIC_EVENT,
  //   values: {
  //     funnelId,
  //     type,
  //     fingerprint,
  //     value: value || 1,
  //   },
  // });


  const body = JSON.stringify({
    funnel: funnelId, //TODO: seems like we should send Page ID ( and i think it is a PAge ID ), so might need to rename it to reflect actual 
    event_type: type,
    fingerprint,
    value: value || 1
  });

  await fetch(`${ANALYTIC_API}/report_event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body
  });
};
