import { useEffect, useState, useRef } from 'react';
import { rxPriceError } from '../../../rx/rxState';
import {
  eventEmitter,
  rxSelectedBlocks,
  rxRerollImageData,
} from '../../../rx/rxState';
import { useObservable } from 'utils/UseObservable';
import TextEditorToolbar from 'Components/TextEditor/TextEditorToolbar';
import RerollComponent from 'Components/AIRerollImage/RerollComponent';

import ToolbarSection from '../ToolbarSection';
import OverflowHandler from '../OverflowHandler/OverflowHandler';
import ToolbarManipulator from '../ToolbarManipulator';
import AttributeComponent from '../../../Attributes/AttributeComponent';

import styles from './ToolbarBlocks.module.scss';

const ToolbarBlocks = () => {
  const [block, setBlock] = useState<any>(null);
  const [counter, setCounter] = useState(0); //Forced Update counter
  const forcedUpdate = () => setCounter((count) => count + 1);

  const priceError = useObservable<string>(rxPriceError);
  const rerollBlockImageData: any = useObservable(rxRerollImageData);

  const originalRef = useRef(null);

  useEffect(() => {
    const selectedBlockSubscription = rxSelectedBlocks.subscribe({
      next: (v) => {
        if (v.length > 0) {
          setBlock(v[0]);
        } else {
          setBlock(null);
        }
        if (
          block === null ||
          (rerollBlockImageData && rerollBlockImageData.block.id !== block.id)
        ) {
          rxRerollImageData.next(null);
        }

        if (rerollBlockImageData) forcedUpdate();
      },
    });

    const eventEmitterSubscription = eventEmitter.subscribe({
      next: (e: any) => {
        if (e.type == 'view-on-scroll') {
          if (rerollBlockImageData) forcedUpdate();
        }
      },
    });

    return () => {
      selectedBlockSubscription.unsubscribe();
      eventEmitterSubscription.unsubscribe();
    };
  }, [rerollBlockImageData]);

  const saveOriginalImage = () => {
    if (rerollBlockImageData) {
      if (rerollBlockImageData.block.image) {
        originalRef.current = rerollBlockImageData.block.image;
      }
      if (rerollBlockImageData.block.imageUrl) {
        originalRef.current = rerollBlockImageData.block.imageUrl.value;
      }
    }
  };

  const restoreOriginalImage = () => {
    if (
      rerollBlockImageData.block.image ||
      rerollBlockImageData.block.imageUrl
    ) {
      rerollBlockImageData.block.image = originalRef.current;
      rerollBlockImageData.block.imageUrl.value = originalRef.current;
      originalRef.current = null;
      rerollBlockImageData.block.view.update();
    }
    rxRerollImageData.next(null);
  };

  const handleChangeImage = (url: string) => {
    rerollBlockImageData.block.imageUrl.value = url;
    rerollBlockImageData.block.view.update();
  };

  const handleSubmit = (url: string) => {
    rxRerollImageData.next(null);
  };

  const onAttributeChanged = (attrName: string, newValue: string) => {
    block.onAttributeChanged(attrName, newValue);
    block[attrName].value = newValue;

    forcedUpdate();
  };

  const getToolbar = (block: any) => {
    if (!block) return null;

    const attributes = block.attributes;
    const visibleAttributes = attributes.filter(
      (attr: any) => attr.visible === undefined || attr.visible !== false
    );

    return (
      <div style={{ display: 'flex' }}>
        <TextEditorToolbar
          block={block}
          parentContainerClass={styles.wrapper}
        />
        <OverflowHandler parentContainerClass={styles.wrapper}>
          {visibleAttributes.length > 0 &&
            visibleAttributes.map((attr: any) => (
              <AttributeComponent
                attribute={attr}
                block={block}
                key={attr.id}
                onChange={onAttributeChanged}
              />
            ))}
        </OverflowHandler>
      </div>
    );
  };

  const getManipulatorToolbar = (block: any) => {
    if (!block) {
      return null;
    }

    if (block.type === 'Section') {
      return <ToolbarSection block={block} />;
    } else {
      return <ToolbarManipulator block={block} />;
    }
  };

  return (
    <div className={styles.wrapper}>
      {getManipulatorToolbar(block)}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'rgb(234, 237, 239)',
          width: '100%',
          height: '100%',
          left: '0px',
          zIndex: '1',
        }}
      ></div>
      <div className={styles.toolbar}>
        {rerollBlockImageData && (
          <div
            style={{
              position: 'absolute',
              left:
                rerollBlockImageData.block.worldRenderBRect.left +
                80 +
                (rerollBlockImageData.block.worldRenderBRect.width - 600) / 2.0, // 600 is a width of AI re-roll toolbar, 80px is a width of side panel
              top: rerollBlockImageData.block.worldRenderBRect.top + 5,
            }}
          >
            <RerollComponent
              immediatelyOpen={true}
              onChange={handleChangeImage}
              onStart={saveOriginalImage}
              onCancel={restoreOriginalImage}
              onSubmit={handleSubmit}
            />
          </div>
        )}
        {getToolbar(block)}
        {priceError && <div className={styles.errorText}>{priceError}</div>}
      </div>
    </div>
  );
};
export default ToolbarBlocks;
