import React, { useState, useEffect } from 'react';
import RcTooltip from 'rc-tooltip';
import s from './Tooltip.module.scss';
import './bootstrap.css';

export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

type TooltipProps = {
  children: JSX.Element;
  text: string;
  placement?: TooltipPlacement;
  visible?: boolean;
};

const Tooltip = (props: TooltipProps) => {
  const placement = props.placement ? props.placement : 'bottom';
  return (
    <RcTooltip
      placement={placement}
      visible={props.visible}
      motion={{
        motionName: 'rc-tooltip-zoom',
        motionDeadline: 1000,
      }}
      overlay={<span> {props.text}</span>}
    >
      {props.children}
    </RcTooltip>
  );
};

export default Tooltip;
