import React, { useEffect, useRef, useState } from 'react';
import s from './Dropdown.module.scss';
import arrow from '../../../Assets/icons/arrow.svg';
import useElementClickedOutside from '../../../Hooks/useElementClickedOutside';

function Dropdowm({
  options,
  initialValue,
  onChange,
  width,
  selectBoxLabel,
  name,
}) {
  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const { ref } = useElementClickedOutside(() => setOpen(false));

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={s.container}>
      <div ref={ref} className={s.dropdown} style={{ width: `${width}px` }}>
        <div
          className={s.control}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className={s.selectedValue}>
            <div className={s.currentValue}>
              <div className={s.name}>{name}:</div>
              <div>{value}</div>
            </div>
          </div>
          <img className={`${s.arrow} ${open ? s.open : null}`} src={arrow} />
        </div>
        <div className={`${s.options} ${open ? s.open : null}`}>
          {options.map((option, i) => (
            <div
              key={i}
              onClick={(e) => {
                if (typeof onChange === 'function') {
                  onChange(option);
                }
                setValue(option.label);
                setOpen(false);
              }}
              className={`${s.option} ${
                value === option.label ? s.selected : null
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dropdowm;
